'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units
 # @description

###
angular
  .module 'mundoAdmin.units', [
    'ui.router'
    'formly'
    'formlyMaterial'
  ]
  .run [
    '$rootScope'
    'UnitPermissions'
    'MundoNavigation'
    ($rootScope, UnitPermissions, MundoNavigation) ->
      MundoNavigation.addSection
        state: 'units'
        label: 'app.admin.units'
        icon: 'star'
        showExpression: 'hasPermission("access unit section")'
        items: [
            state: 'units.overview'
            label: 'app.admin.pageTitles.units.units'
            icon: 'star'
            showExpression: 'hasPermission("view all MundoMosaUnitBundle:Unit entities")'
          ,
            state: 'units.groups'
            label: 'app.admin.pageTitles.units.unitGroups'
            icon: 'stars'
            showExpression: 'hasPermission("view all MundoMosaUnitBundle:UnitGroup entities")'
        ]

      $rootScope.$on 'mundo:authentication:user:permissions:loaded', (event, data) ->
        if not $rootScope.hasUnitPermission?
          $rootScope.hasUnitPermission = (permission, unitId) ->
            return ($rootScope.hasPermission(permission) or UnitPermissions.check(permission, unitId))

          UnitPermissions.reloadPermissions()
  ]
